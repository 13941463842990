import React from 'react'

import Nav from '../components/Nav';
import Footer from '../components/Footer';

import Hero from '../components/Hero';

import { graphql, useStaticQuery } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';

export default function Ventures() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const data = useStaticQuery(
        graphql`
        query {
            file(relativePath: {eq: "Heros/international.jpg"}) {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
          }
        `)

    const heroImage = data.file.childImageSharp.gatsbyImageData;


    return (
        <div>
            <Nav isActive="About"/>
            <Hero title="Ventures" image={heroImage}/>

          

            

            <div className="grid h-52">
              <StaticImage
                  style={{gridArea: "1/1"}}
                  layout="fullWidth"
                  aspectRatio={2/1}
                  alt="Background Image"
                  src={"../images/about/development.jpg"}
                  formats={["webp", "avif"]}
              />
              <div
                  style={{
                  gridArea: "1/1",
                  position: "relative",
                  placeItems: "center",
                  display: "grid",
                  }}
                  className="bg-sphinx-accent bg-opacity-40">
                      <div className="text-sphinx-white text-xl font-montserrat font-light lg:text-2xl flex justify-center flex-wrap">
                        <h1 className="text-4xl text-center text-sphinx-white w-full">Learn More About Our Development Projects!</h1>
                        <a href="/development" className="text-base text-center mt-5 p-2 max-w-max text-sphinx-white font-montserrat border-sphinx-white border-2 flex items-center hover:bg-sphinx-white hover:text-sphinx-accent hover:shadow-lg">Development</a>
                      </div>
              </div>
            </div>
            <Footer/>
        </div>
    )
}
